<template>
  <div class="w-full">
    <label v-if="showLabel" class="phone-label" for="phone-number">
      {{$t("business_request.page_1.phone.label")}}
    </label>
    <div class="input-select-container">
      <select
          v-model="countryCodeModel"
          :readonly="readonlyAuth"
          name="dial-code"
          @change="emitPhoneNumber"
      >
        <option
            v-for="(country, index) in langCountries"
            :key="index"
            :value="country"
        >
          {{ country[lang] }} ({{ country.dial_code }})
        </option>
      </select>

      <div class="input-container">
        <span>{{ countryCodeModel.dial_code }}</span>
        <input
            id="phone-number"
            :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark']"
            v-model="numberInput"
            type="number"
            @input="emitPhoneNumber"
            @blur="$emit('blur')"
            :placeholder="inputPlaceholder"
        />
      </div>
    </div>
    <ErrorMessage v-if="showErrMsg" :error-msg="errorMsg"/>
  </div>
</template>
<script>
import ErrorMessage from "@/components/ErrorMessage";
import phone from "@/data/phonecodes/phone.json";
import timeZones from "@/data/countries/timeZones.json";
import {parsePhoneNumber,getExample} from "awesome-phonenumber";

export default {
  name: "InputPhone",
  inheritAttrs: false,
  components: {
    ErrorMessage
  },
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    readonlyAuth: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "en",
      countryCodeModel: phone[0],
      numberInput: "",
    };
  },
  computed: {
    //Sort phone json file alphabetically by English names
    langCountries() {
      return phone.sort((a, b) => {
        return a[this.lang].localeCompare(b[this.lang]);
      });
    },
    inputPlaceholder(){
      return getExample(this.countryCodeModel.code,'mobile').number.significant;
    }
  },
  methods: {
    emitPhoneNumber() {
      const pn = parsePhoneNumber(this.numberInput, {regionCode: this.countryCodeModel.code}).number.international;
      this.$emit("select", pn);
      this.$emit('input', pn)
      this.$emit("textfield", pn);
    },
    setAndParsePhoneNumber(number) {
      let pn = parsePhoneNumber(number);
      if (pn.number.input !== "") {
        this.numberInput = pn.number.significant;
        this.countryCodeModel = phone.find((object) => object.code === pn.regionCode);
      }
    }
  },
  mounted() {
    if (this.value !== "") {
      this.setAndParsePhoneNumber(this.value);
    } else {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (timezone === "" || !timezone) {
        return null;
      }
      this.countryCodeModel = phone.find((object) => object.code === timeZones[timezone].c[0]);
    }
  },
  watch:{
    value(newVal){
      this.setAndParsePhoneNumber(newVal);
    }
  }
};
</script>

<style scoped>
input,
select {
  @apply leading-tight w-full p-3 border rounded;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply text-black-base placeholder-black-lightest;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-select-container {
  @apply grid grid-cols-12 gap-3 sm:gap-4 w-full;
}

select,
input {
  @apply border rounded-lg;
}

select {
  @apply border-grey-dark;
}

input {
  @apply pl-20;
}

.phone-label {
  @apply mb-3 block font-semibold text-black-base;
}

.input-select-container select {
  @apply col-span-12 xs:col-span-5;
}

.input-container {
  @apply col-span-12 xs:col-span-7 relative;
}

.input-container span {
  @apply absolute top-1/2 transform -translate-y-1/2 left-3;
  @apply text-black-lightest border-r border-grey-base;
  @apply w-14;
}

</style>